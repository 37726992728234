/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-09-14 10:15:46
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-12-15 11:04:49
 * @FilePath: \zyy\src\main.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import {getToken, setToken} from "@/utils/utils";
import { Popup, Picker, Form, Field, Button, Checkbox, CheckboxGroup, Dialog, Toast, Icon } from 'vant';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import store from './store/index.js';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import 'vant/lib/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'dayjs/locale/zh-cn';
import { $getData, $postData, $compression } from '@/axios/index.js';
import LWraper from '@/components/common/LWraper.vue';
import { ElMessage, ElMessageBox } from 'element-plus'
window.axiosPromiseCancel = [];

let app = createApp(App);
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$confirm = ElMessageBox;
app.config.globalProperties.$getData = $getData;
app.config.globalProperties.$postData = $postData;
app.config.globalProperties.$compression = $compression
app.component('l-wraper', LWraper);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(Popup).use(Picker).use(Button).use(Form).use(Field).use(CheckboxGroup).use(Checkbox).use(Dialog).use(Toast).use(Icon)

app.use( ElementPlus ).use(store)
.use(router)
.mount('#app');

// 全局路由
router.beforeEach((to, from, next) => {
  if (to.query.token) {
    setToken(to.query.token);
    const _query = JSON.parse(JSON.stringify(to.query));
    delete _query.token;
    next({
      path: to.path,
      query: _query,
    });
    return;
  }
	let { noLogin } = to.meta;
	let token = store.state.token || getToken();
	if (!noLogin) {
		if (token) {
			next();
		} else {
			if (/www.cxlqd/.test(location.host)) {
				next({
					name: 'lqdlogin',
				});
			} else {
				next({
					name: 'login',
				});
			}
		}
	} else {
		next();
	}
	// (window as any).axiosPromiseCancel.forEach((e: any) => {
	// 	if (e) e();
	// 	(window as any).axiosPromiseCancel = [];
	// });
});

// router.afterEach((to, from) => {
//   console.log(to, from);
// });
