import { saveAs } from 'file-saver';

export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substring(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

//导出文件下载并重命名
export const exportExcel = (url, fileName, isNeedDate=true, suffix='xlsx') => {
    let rename = fileName;
    if ( isNeedDate ) {
      const date = new Date();
      rename = `${
        rename +
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日" +
        date.getHours() +
        "时" +
        date.getMinutes() +
        "分" +
        date.getSeconds() +
        "秒"
      }`;
    }
    saveAs(url, `${rename}.${suffix}`);
}

export function getToken() {
  let token = localStorage.getItem("zt");
  try {
    const _token = JSON.parse(localStorage.getItem("token") || "{}").token;
    if (_token) {
      token = _token;
    }
  } catch (e) {
    console.log("token structure error", e);
  }
  return token || undefined;
}

export function setToken(token) {
  if (token) {
    localStorage.setItem("token", JSON.stringify({token}));
  } else {
    localStorage.removeItem("token");
  }
}
